<template>
<div class="slide-in space-y-6 mx-auto w-full h-screen">
    <div class="flex flex-wrap justify-between w-full">
      <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="HMO PLANS"
              :statTitle="total.plans"
              statIconName="far fa-file"
              statIconColor="bg-red-500"
            />
       </div>
        <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="ENROLLEES"
              :statTitle="total.enrollees"
              statIconName="fas fa-user-friends"
              statIconColor="bg-red-500"
            />
       </div>
        
       <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="COST"
              :statTitle="total.cost"
              statIconName="far fa-chart-bar"
              statIconColor="bg-red-500"
            />
       </div>
    </div>

   <div class="bg-white border border-gray-300 rounded  h-auto">
    <div class="flex justify-between px-5 py-4"  style="height: 10%">
        <div class="w-full text-primary font-semibold py-1 flex items-center">
          <div class="flex items-center">
            <span>HMO Plans Subscribed</span>
          </div>
        </div>
       
      </div>

        <div  class="w-full hidden  px-4 justify-between py-2 border-t-2 border-b-2 border-gray-500  md:flex flex-row">
            <div class="w-1/12">SN</div>
            <div class="w-5/12">Name</div>
            <div class="w-1/12">Enrollees</div>
            <div class="w-2/12">Price</div>
            <div class="w-2/12">Cost</div>
        </div>

         <friendly-loader :show="loading == true" />
         <div v-if="loading == false && (plansSummary.length == 0) " class="w-full  md:flex md:flex-row  px-4 justify-between py-2 border-b border-gray-500 ">
             <h3 class=" mx-auto font-bold my-4">You do not have any HMO Plans yet.</h3>
         </div>
        <div v-for="detail,index in plansSummary" :key="index" class="  transition transform hover:-translate-y-2 w-full  md:flex md:flex-row  px-4 justify-between py-2 border-b border-gray-500 ">
            <div class=" hidden md:inline-block w-1/12">{{ index + 1 }}</div>
            <div class="w-5/12">{{ detail.plan_name}}</div>
            <div class="w-1/12 flex flex-row items-center"><span class="md:hidden mr-2 fas fa-user-friends"></span><span>{{ detail.enrollees_count }}</span></div>
            <div class="w-2/12">{{ $filters.toCurrency(detail.plan_price) }}</div>
            <div class="w-2/12">{{ $filters.toCurrency(detail.plan_cost) }}</div>
        </div>

        
        
      <div class="flex flex-wrap justify-between px-5 py-4 font-semibold text-primary"    style="height: 10%">
            <div class="">TOTAL</div>
            <div class="md:mr-4"> {{ total.cost }} </div>
      </div>
 
   </div>

   
</div>


</template>
<script>
import CardStats from './components/CardStats.vue'
import { ErrorToast} from "@/shared/mixins/Toast";
import FriendlyLoader from  './components/FriendlyLoader.vue'
export default {
  
  name:'dashboard-component',
  data () {
    return {
      loading: undefined,
    }
  },
  
  created () {
    //to save delays we load this data once
        this.loadDashboard()
      
  },
  computed:{

      plansSummary(){
         return this.$store.getters['dashboard/plansSummary']
      },
      total(){
          let cost = this.$store.getters['dashboard/plansSummary']
          .reduce(function(accumulator,detail){
                return accumulator + Math.round(detail.plan_cost)
          },0)
          
          let enrollees = this.$store.getters['dashboard/plansSummary']
          .reduce(function(accumulator,detail){
                return accumulator + detail.enrollees_count
          },0)

          let plans = this.$store.getters['dashboard/plansSummary'].length
           
           cost =  this.$filters.toCurrency(cost);
           plans = this.$filters.toNumber(plans);
           enrollees = this.$filters.toNumber(enrollees);
           
          return { enrollees , plans, cost }
      }
  },
  components: {
     CardStats,
     FriendlyLoader,
      },
      methods: {
        
      loadDashboard () { 
      //load the dashboard only once
      if(this.plansSummary.length > 0){
        return;
      }
      this.loading = true;
      return this.$store.dispatch("dashboard/fetchPlansSummary")
              .then((res) =>{
                //the handles this
              })
              .catch((err) => {
            ErrorToast.fire({
              title: `<span style="color:#fff">${err.response.data.message}</span>`,
            });
          })
          .finally(() => {
  
            this.loading = false;
            });
    },
    
  },


}
</script>
<style>
    
</style>