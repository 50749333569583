<template lang="">
    <div v-if="show" class="w-full  md:flex md:flex-row  px-4 justify-between py-2 border-b border-gray-500 ">
        <div class=" p-4 w-full mx-auto">
        <div class="animate-pulse flex space-x-4">
        <!-- <div class="rounded-full bg-blue-400 h-12 w-12"></div> -->
        <div class="flex-1 space-y-4 py-1">
            <div class="h-4 bg-blue-400 rounded w-full"></div>
            <div class="space-y-2">
                <div class="h-4 bg-blue-400 rounded"></div>
                <div class="h-4 bg-blue-400 rounded w-5/6"></div>
            </div>
        </div>
        </div>
        </div>
        </div>
      

</template>
<script>
export default {
  
    name: 'friendly-loader',
    
    props: {
        show: {
            type: Boolean,
            required: true,
        }
  },
}
</script>
<style lang="">
    
</style>